@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Antiqua";
  src: local("Antiqua"), url("./Fonts/ANTQUAB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Antiqua";
  src: local("Antiqua"), url("./Fonts/BKANT.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Antiqua";
  src: local("Antiqua"), url("./Fonts/ANTQUABI.TTF") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Antiqua";
  src: local("Antiqua"), url("./Fonts/ANTQUAI.TTF") format("truetype");
  font-weight: normal;
  font-style: italic;
}

body {
  font-family: Antiqua;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Tailwind Extensions */
.text-primary {
  color: #666363;
}

.text-secondary {
  color: #aeaeae;
}

/* Other CS|S */
.container {
  display: block;
  position: relative;
  padding-left: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border-color: #666363;
  border-width: 1px;
  border-radius: 12px;
}

.container input:checked ~ .checkmark {
  background-color: #666363;
}

.textbox {
  background-color: transparent;
  border: 0.25px solid #666363;
  border-left: none;
  border-top: none;
  border-right: none;
}
