.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
}

.App-nav {
  background-color: #ffffff;
  /* min-height: 8vh; */
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.Details-City-Info {
  text-align: left;
}

.Button-main {
  margin: 30px;
  font-size: 18px;
  height: 65px;
  width: 250px;
  border-radius: 10px;
  border: none;
  box-shadow: 1px 1px 0px 2px rgba (0, 0, 0, 0.3);
  background: rgb(141, 217, 252);
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
